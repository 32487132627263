<template>
  <div>
    <b-card>
      <b-table
        sticky-header
        striped
        hover
        small
        :items="items"
        :fields="fields"
      >
      </b-table>
    </b-card>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { BTable, BCard } from "bootstrap-vue";

const fields = ref([
  { key: "no", label: "No", sortable: true },
  { key: "tanggal", label: "Tanggal", sortable: true },
  { key: "nama", label: "Nama", sortable: true },
  { key: "telepon", label: "Telepon", sortable: true },
  { key: "alamat", label: "Alamat", sortable: true },
  { key: "tanggalLahir", label: "Tanggal Lahir", sortable: true },
  { key: "jenisKelamin", label: "Jenis Kelamin", sortable: true },
  { key: "golonganDarah", label: "Gol. Darah", sortable: true },
]);

const items = ref([
  {
    no: 1,
    tanggal: "2024-06-27",
    nama: "John Doe",
    telepon: "123-456-7890",
    alamat: "Jl. Contoh No. 123",
    tanggalLahir: "1990-01-01",
    jenisKelamin: "Laki-laki",
    golonganDarah: "A",
  },
  {
    no: 2,
    tanggal: "2024-06-28",
    nama: "Jane Smith",
    telepon: "987-654-3210",
    alamat: "Jl. Percobaan No. 456",
    tanggalLahir: "1992-02-02",
    jenisKelamin: "Perempuan",
    golonganDarah: "B",
  },
  {
    no: 3,
    tanggal: "2024-06-29",
    nama: "Bob Johnson",
    telepon: "555-555-5555",
    alamat: "Jl. Contoh No. 789",
    tanggalLahir: "1985-03-03",
    jenisKelamin: "Laki-laki",
    golonganDarah: "AB",
  },
]);
</script>
